import React, { useEffect } from 'react';
import { useEffectOnce, usePrevious } from 'react-use';

import { GameSessionUtil } from '@lp-lib/game';

import { useInstance } from '../../hooks/useInstance';
import { useVenueMode } from '../../hooks/useVenueMode';
import logger from '../../logger/logger';
import { type SessionId } from '../../types';
import {
  NotificationType,
  type TeamCaptainScribeNotification,
} from '../../types/notification';
import { VenueMode } from '../../types/venue';
import { nullOrUndefined, uuidv4 } from '../../utils/common';
import { BlockKnifeUtils } from '../Game/Blocks/Shared';
import { useGameSessionBlock, useGameSessionStatus } from '../Game/hooks';
import { useNotificationDataSource } from '../Notification/Context';
import { type BaseNotificationProps } from '../Notification/type';
import { useAmICohost, useMyTeamId } from '../Player';
import { useIsStreamSessionAlive, useStreamSessionId } from '../Session';
import { useSoundEffect } from '../SFX';
import {
  useIsTeamCaptainScribe,
  useTeamCaptainScribe,
  useTeamMembers,
} from '../TeamAPI/TeamV1';
import { useMyClientId } from '../Venue/VenuePlaygroundProvider';
import { TeamCaptainScribeBadge } from './TeamCaptainScribeBadge';

const log = logger.scoped('team-captain-notification-dispatcher');

function useTeamCaptainTransferDispatcher(
  tracking: Map<SessionId, Date>
): void {
  const myTeamId = useMyTeamId();
  const myClientId = useMyClientId();
  const isTeamCaptainScribe = useIsTeamCaptainScribe(myTeamId, myClientId);
  const prevIsTeamCaptainScribe = usePrevious(isTeamCaptainScribe);
  const prevMyTeamId = usePrevious(myTeamId);
  const teamCaptainScribe = useTeamCaptainScribe(myTeamId, true);
  const previousTeamCaptainScribe = usePrevious(teamCaptainScribe);
  const { send: sendNotification } = useNotificationDataSource();
  const streamSessionId = useStreamSessionId();
  const amICohost = useAmICohost();

  const venueMode = useVenueMode();

  useEffect(() => {
    // Goal: only show notif if newly annointed as captain during the game,
    // which can only happen if there was already previously a teamCaptainScribe

    if (
      previousTeamCaptainScribe &&
      isTeamCaptainScribe &&
      !prevIsTeamCaptainScribe &&
      prevMyTeamId &&
      prevMyTeamId === myTeamId &&
      venueMode !== VenueMode.Lobby &&
      !amICohost
    ) {
      sendNotification({
        id: uuidv4(),
        toUserClientId: myClientId,
        type: NotificationType.TeamCaptainScribe,
        createdAt: Date.now(),
      });
      if (streamSessionId) {
        tracking.set(streamSessionId, new Date());
      }
      log.info('team captain transferred');
    }
  }, [
    isTeamCaptainScribe,
    myClientId,
    myTeamId,
    sendNotification,
    prevIsTeamCaptainScribe,
    prevMyTeamId,
    previousTeamCaptainScribe,
    venueMode,
    streamSessionId,
    tracking,
    amICohost,
  ]);
}

function useDispatchWhenPresentingFirstEligibleBlock(
  tracking: Map<SessionId, Date>
): void {
  const block = useGameSessionBlock();
  const status = useGameSessionStatus();
  const streamSessionId = useStreamSessionId();
  const isStreamSessionAlive = useIsStreamSessionAlive();
  const myTeamId = useMyTeamId();
  const myClientId = useMyClientId();
  const isTeamCaptainScribe = useIsTeamCaptainScribe(myTeamId, myClientId);
  const mapped = GameSessionUtil.StatusMapFor(block?.type);
  const { send: sendNotification } = useNotificationDataSource();
  const eligible = block
    ? BlockKnifeUtils.IsEligibleForTeamCapainPlay(block)
    : false;
  const amICohost = useAmICohost();
  const membersCount = useTeamMembers(myTeamId)?.length ?? 0;

  useEffect(() => {
    log.debug('first eligible block dispatcher params', {
      isIntro: mapped?.intro === status,
      streamSessionId,
      isStreamSessionAlive,
      lastDispatchedAt: streamSessionId
        ? tracking.get(streamSessionId)
        : undefined,
      isTeamCaptainScribe,
      eligible,
      amICohost,
      membersCount,
    });
    if (!eligible) return;
    if (nullOrUndefined(mapped) || nullOrUndefined(status)) return;
    if (status !== mapped.intro) return;
    if (!streamSessionId) return;
    if (!isStreamSessionAlive) return;
    if (tracking.get(streamSessionId)) return;
    if (!isTeamCaptainScribe) return;
    if (amICohost) return;
    if (membersCount <= 1) return;
    sendNotification({
      id: uuidv4(),
      toUserClientId: myClientId,
      type: NotificationType.TeamCaptainScribe,
      createdAt: Date.now(),
    });
    tracking.set(streamSessionId, new Date());
    log.info('first eligible block dispatched');
  }, [
    isStreamSessionAlive,
    isTeamCaptainScribe,
    mapped,
    myClientId,
    sendNotification,
    status,
    streamSessionId,
    tracking,
    eligible,
    amICohost,
    membersCount,
  ]);
}

export function TeamCaptainScribeNotificationDispatcher(): null {
  // track if the notification has been dispatched in the same session
  const tracking = useInstance(() => new Map<SessionId, Date>());
  useTeamCaptainTransferDispatcher(tracking);
  useDispatchWhenPresentingFirstEligibleBlock(tracking);
  return null;
}

export const TeamCaptainScribeNotificationView = (
  props: BaseNotificationProps & {
    notification: TeamCaptainScribeNotification;
  }
): JSX.Element => {
  const notificationDataSource = useNotificationDataSource();

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    notificationDataSource.dismiss(props.notification.id);
  };

  const { play } = useSoundEffect('teamInvite');

  useEffectOnce(() => {
    notificationDataSource.dismissByType(
      NotificationType.TeamCaptainScribe,
      props.notification.id
    );
  });

  useEffect(() => {
    play();
  }, [play]);

  useEffect(() => {
    // Autodismiss after 10s
    const id = setTimeout(() => {
      notificationDataSource.dismiss(props.notification.id);
    }, 10000);

    return () => {
      clearTimeout(id);
    };
  }, [notificationDataSource, props.notification.id]);

  return (
    <div
      className='
        w-166 h-25
        text-main-layer
        rounded-xl
        bg-gradient-to-tr from-yellow-start to-yellow-end
    '
    >
      <div className='flex flex-row w-full h-full items-center justify-center px-8'>
        <div className='flex flex-col w-full h-full items-start justify-center'>
          <div className='text-sm font-bold w-100'>
            You’ve been assigned the role of Team Captain. You’ll submit answers
            for your team.
          </div>
          <div
            className='text-2xs justify-center mt-2 w-112'
            style={{ lineHeight: '8px' }}
          >
            <span>If you’re not feeling up to the task, click this</span>{' '}
            <TeamCaptainScribeBadge className='transform-gpu origin-left scale-85 mx-1 inline-block' />
            <span>badge below to choose another captain</span>
          </div>
        </div>

        <div className='ml-auto'>
          <button
            type='button'
            className='w-32 h-10 btn-secondary text-white'
            onClick={handleClose}
          >
            Got It!
          </button>
        </div>
      </div>
    </div>
  );
};
